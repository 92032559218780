import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const poweredBy = ({ classes }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return <div className={classes.poweredBy}>Powered by {data.site.siteMetadata.title}</div>
}

export default poweredBy
