import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
  },
  card: {
    maxWidth: 600,
    margin: `${theme.spacing(10)}px auto`,
    boxShadow: theme.shadows[15],
  },
  poweredBy: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    fontSize: '0.7rem',
  },
}))
