import React, { createContext, useContext, useState, useEffect } from 'react'
import { customerLogin, guestLogin } from 'actions/login'
import { getCustomer } from 'actions/customer'

export const AuthContext = createContext({})

export const AuthProvider = ({ children, search }) => {
  const query = new URLSearchParams(search)
  const token = query.get('token')
  const [auth, setAuth] = useState({ customer: null, user: null })

  useEffect(async () => {
    if (token) {
      const { user } = await customerLogin(token)
      const customer = await getCustomer(user.uid)
      setAuth({ user, customer })
    } else {
      const { user } = await guestLogin()
      setAuth({ user })
    }
  }, [])

  return <AuthContext.Provider value={{ ...auth }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
