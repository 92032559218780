import React, { memo } from 'react'
import Logo from 'components/Logo'
import { AppBar } from '@material-ui/core'
import useStyles from './publiStyles'
import PoweredBy from './poweredBy'

const PublicHeader = memo(() => {
  const classes = useStyles()

  return (
    <AppBar className={classes.root} color="default" position="relative">
      <Logo />
      <PoweredBy classes={classes} />
    </AppBar>
  )
})

export default PublicHeader
