import Customer from './index'
import timestamp from 'utils/firebaseTimestamp'
import { defaults as fieldDefaults } from 'utils/fields'

const CustomerConverter = {
  toFirestore: (customer) => {
    const { id, roles, ...fs } = customer
    return { ...fs, ...timestamp(id) }
  },
  fromFirestore: (snapshot, options) => {
    const customer = snapshot.data(options)
    return new Customer(
      customer.name,
      customer.email,
      customer.phone,
      customer.meta ?? fieldDefaults?.meta,
      customer.deletedAt?.toDate(),
      snapshot.id
    )
  },
}

export default CustomerConverter
