import React, { forwardRef } from 'react'
import { CircularProgress, Button } from '@material-ui/core'
import useStyles from './styles'

const LoadingButton = forwardRef(
  ({ isLoading, variant = 'contained', children, disabled, loadingSize = 25, ...props }, ref) => {
    const classes = useStyles()
    return (
      <Button ref={ref} variant={variant} {...props} className={classes.button} disabled={disabled || isLoading}>
        {isLoading ? <CircularProgress size={loadingSize} /> : children}
      </Button>
    )
  }
)

export default LoadingButton
