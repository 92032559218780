import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1.8, 'auto'),
    padding: theme.spacing(2),
    fontSize: '0.8rem',
    textAlign: 'center',
  },
}))
