import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'

class CustomerModel extends BaseModel {
  constructor(name = '', email = '', phone = '', meta = null, deletedAt = null, id = null) {
    super()
    this.name = name
    this.email = email
    this.phone = phone
    this.meta = meta
    this.deletedAt = deletedAt
    this.id = id
  }

  toJSON() {
    return {
      name: this.name,
      email: this.email,
      phone: this.phone,
      meta: this.meta,
      id: this.id,
    }
  }

  toFormDefaultJSON() {
    return {
      name: this.name,
      email: this.email,
      phone: this.phone,
      meta: this.meta,
    }
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    email: yup.string().email().label('Email').required(),
    roles: yup.array().label('Roles').required(),
  })

  static profileValidationSchema = yup.object({
    name: yup.string().label('Name').required(),
    email: yup.string().email().label('Email').required(),
  })
}

export default CustomerModel
