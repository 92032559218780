import { useImperativeHandle, useState } from "react";

export default function useOpenClose(ref, startsOpen = false) {
  const [open, setOpen] = useState(startsOpen);
  const [prev, setPrev] = useState(startsOpen);

  useImperativeHandle(ref, () => ({
    isOpen: open,
    resetPrev: () => setOpen(prev),
    setOpen: (o) => setOpen(o),
    open: () => {
      setPrev(open);
      setOpen(true);
    },
    close: () => {
      setPrev(open);
      setOpen(false);
    },
    toggle: () => {
      setPrev(open);
      setOpen(!open);
    },
  }));

  return { open };
}

export function useOpenCloseAnchor(ref, startAnchorEl = null) {
  const [anchor, setAnchor] = useState(startAnchorEl);

  useImperativeHandle(ref, () => ({
    open: (anchorEl) => setAnchor(anchorEl),
    close: () => setAnchor(null),
    toggle: (anchorEl) => setAnchor(open ? null : anchorEl),
  }));

  return { anchor };
}

export function useOpenCloseCallback(
  ref,
  {
    startsOpen = false,
    afterOpen = () => {},
    afterClose = () => {},
    afterToggle = () => {},
  }
) {
  const [open, setOpen] = useState(startsOpen);

  useImperativeHandle(ref, () => ({
    open: (...params) => {
      setOpen(true);
      afterOpen(...params);
    },
    close: (...params) => {
      setOpen(false);
      afterClose(...params);
    },
    toggle: (...params) => {
      setOpen(!open);
      afterToggle(...params);
    },
  }));

  return { open };
}
