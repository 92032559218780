import React from 'react'
import useStyles from './styles'
import { useStaticQuery, graphql } from 'gatsby'

const Footer = () => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={classes.root}>
      {data.site.siteMetadata.title} © {new Date().getFullYear()}
    </div>
  )
}

export default Footer
